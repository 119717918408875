html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgb(11,20,26), rgb(10,19,25)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0),
    rgba(19, 23, 24, 0.644)
  );

  --imp-text-color: #ffffff;
}


.purple {
  color: var(--imp-text-color) !important;
  font-family: Poppins;
}


button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #000000;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:   rgba(34, 45, 164, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(130, 148, 235, 0.911);
  border-radius: 12px;
}

/* Navbar */


.navbar {
  width: 100% !important;
  background-color: transparent !important;
  transition: all 0.4s ease-out !important;
  box-shadow: none !important;
  padding: 0.5rem 2rem !important;
  font-size: 1.1rem !important;
  z-index: 10 !important;
}

.d-flex {
  display: flex;
  justify-content: flex-start; /* Aligns content to the left */
  align-items: center; /* Centers content vertically */
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sticky {
  background-color: rgba(0, 0, 51, 0.8) !important;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3) !important;
  transition: all 0.4s ease-out !important;
}

/* Brand Styling */
.navbar-brand {
  font-size: 1.8rem !important;
  color: #fff !important;
  transition: color 0.4s ease-out !important;
}




/* Toggler Styling */
.navbar-toggler {
  border: none !important;
  outline: none !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #fff !important;
  height: 3px !important;
  width: 25px !important;
  margin: 4px !important;
  transition: all 0.3s ease-in-out !important;
}


/* Nav Link Styling */
.navbar-nav .nav-link {
  color: #fff !important;
  margin-right: 1rem !important;
  transition: color 0.4s ease-out !important;
  cursor: pointer;
}

.navbar-nav .nav-link:hover {
  color: #5bb5f5 !important;
}

.navbar-nav .nav-link::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background: #5bb5f5;
  transition: width 0.3s ease-out;
}

.navbar-nav .nav-link:hover::after {
  width: 100%;
}

/* Mobile View */
@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    background-color: rgba(0, 0, 51, 0.8) !important;
  }

  .navbar-nav .nav-item {
    margin-left: 0 !important;
  }

  .navbar-nav .nav-link {
    margin-right: 0 !important;
  }
}



/* Home Section */
.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url(./Assets/bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 150px !important;
  padding-top: 30px !important;
  background-size: cover;
  height: 100vh;
}
.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
@media (max-width: 768px) {
  .home-section {
    background-size: 100% 100%; 
    height: 100vh;
  }
}

.heading{
  font-family: Anton;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
  margin-top: -30px;
}

.heading {
  font-size: 3em !important;
  padding-left: 50px !important;
  margin-top: -70px;

}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #ffffff;

}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-family: Anton;
}
.typer{
  margin-top: -40px;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #ffffff !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

/* About Me Section */
.about-text{
  color:#ffffff;
  font-family: Poppins;
}

.about-languages{
  color:#00599C
}
.home-about-section {
  position: relative;
  padding: 70px 0 !important;
  background: linear-gradient(to right, rgb(11,20,26), rgb(10,19,25)); /* Blue gradient background */
}

.home-about-pic {
  color: white !important;
  text-align: left;
  border-radius: 50%; 
  border-width: 5px; 


  border: 5px solid white; 
  box-sizing: border-box; 
  display: block; 
  margin: 0 auto; 
}


.home-about-description {
  color: white !important;
  text-align: left;
}

.home-about-body {
  font-size: 1.2em !important;
  font-family: 'Poppins', sans-serif;
}

/* Text Transition */
.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

/* Image Styling */
.about-img {
  border-radius: 50%;
  width: 100%;
  max-width: 300px;
  border: 5px solid #fff;
  transition: transform 0.5s ease;
}

.about-img:hover {
  transform: scale(1.1);
}

/* Responsive Layout */
@media (max-width: 767px) {
  .home-about-description {
    text-align: center;
  }

  .about-img {
    width: 80%;
    margin: 0 auto;
  }
}



/* --------- */
/* Footer */
/* --------- */
.footer {

  color: #fff;
  padding: 40px 20px;
  position: relative;
  bottom: 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links h3 {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin: 10px 0;
}

.footer-link {
  color: #f0f0f0;
  text-decoration: none;
  font-size: 1em;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #f39c12;
}

.footer-copywright h3 {
  margin: 10px 0;
  font-size: 1em;
}

.footer-social {
  margin-bottom: 20px;
}

.footer-social h3 {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.social-icons a {
  color: #fff;
  font-size: 1.5em;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #f39c12;
}

@media (max-width: 767px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 150px !important;
  background-image: var(--section-background-color) !important;
  
}
.d-flex {
  display: flex;
  justify-content: flex-start; /* Aligns content to the left horizontally */
  align-items: flex-start; /* Aligns content to the top left vertically */
}
.projects-list {
  display: grid; 
  grid-template-columns: repeat(2, 1fr); 
  gap: 30px; 
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.project-section.visible .projects-list {
  opacity: 1;
  transform: translateY(0);
}

.project-card {
  position: relative;
  overflow: hidden;
  transition: transform 1s ease;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px; 
  margin: 0 auto; 
}

.project-image {
  width: 100%;
  height: auto;
  max-height: 400px; 
  border-radius: 10px;
  transition: opacity 0.5s ease;
  object-fit: cover; 
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.project-card:hover .hover-overlay {
  opacity: 1;
}


.large-image .project-image {
  height: 358px; 
  width: auto; 
  object-fit: cover; 
}


.large-image .project-image-wrapper {
  max-width: 100% !important; 
  height: 358px;
  padding: 0; 
}

.large-image2 .project-image {
  height: 358px; 
  width: 550px; 
  object-fit: cover; 
}


.large-image2 .project-image-wrapper {
  max-width: 100% !important; 
  height: 358px; 
  padding: 0; 
}


.large-image3 .project-image {
  height: 358px; 
  width: 535px;
  object-fit: cover;
}


.large-image3 .project-image-wrapper {
  max-width: 99% !important; 
  height: 358px;
  padding: 0;
}



.hover-text {
  text-align: center;
  padding: 10px;
}

.hover-text h2 {
  margin-bottom: 10px;
}

.hover-text p {
  margin-bottom: 20px;
}

.hover-text a {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
}

.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 1px !important;
  margin-bottom: 70px;
  margin-top: -60px;
}

@media screen and (max-width: 768px) {
  .projects-list {
    grid-template-columns: 1fr; /* Stack items vertically on small screens */
  }
}



/* Skills Section */
.skill-section {
  margin-top: 100px;
  padding: 20px;
  margin-bottom: 100px;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.skill-section.animate {
  opacity: 1;
  transform: translateX(0);
}

.skill-title {
  color: #ffffff;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 2s ease-out, transform 2s ease-out;
  font-family: Poppins;
}

.skill-section.animate .skill-title {
  opacity: 1;
  transform: translateY(0);
}

.tech-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  font-size: 100px;
}

.tech-icons .icon {
  opacity: 0; /* Start as invisible */
  transform: translateY(20px); /* Start slightly below */
  transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, opacity 1s ease;
}

.skill-section.animate .tech-icons .icon {
  opacity: 1;
  transform: translateY(0);
}

/* Hover effects */
.tech-icons .icon:hover {
  transform: scale(1.2); /* Slight scaling on hover */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow */
}

/* Pulse effect */
.tech-icons .icon:hover {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Keyframes for fading in icons */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation delay for each icon */
.tech-icons .icon:nth-child(1) { animation-delay: 0.2s; }
.tech-icons .icon:nth-child(2) { animation-delay: 0.4s; }
.tech-icons .icon:nth-child(3) { animation-delay: 0.6s; }
.tech-icons .icon:nth-child(4) { animation-delay: 0.8s; }
.tech-icons .icon:nth-child(5) { animation-delay: 1s; }
.tech-icons .icon:nth-child(6) { animation-delay: 1.2s; }
.tech-icons .icon:nth-child(7) { animation-delay: 1.4s; }
.tech-icons .icon:nth-child(8) { animation-delay: 1.6s; }
.tech-icons .icon:nth-child(9) { animation-delay: 1.8s; }
.tech-icons .icon:nth-child(10) { animation-delay: 2s; }

/* Icon colors */
.tech-icons .icon.di-python svg { color: #3776AB; }
.tech-icons .icon.io5-firebase svg { color: #FFA611; }
.tech-icons .icon.cg-cplusplus svg { color: #00599C; }
.tech-icons .icon.di-java svg { color: #007396; }
.tech-icons .icon.di-html5 svg { color: #E34F26; }
.tech-icons .icon.di-css3 svg { color: #1572B6; }
.tech-icons .icon.di-jsbadge svg { color: #F7DF1E; }
.tech-icons .icon.di-react svg { color: #61DAFB; }
.tech-icons .icon.di-nodejs svg { color: #339933; }
.tech-icons .icon.di-git svg { color: #F05032; }
.tech-icons .icon.di-mysql svg { color: #4479A1; }

/* Responsive design for smaller screens */
@media (max-width: 767px) {
  .tech-icons {
    font-size: 50px; /* Adjust icon size for smaller screens */
    margin: 10px;
  }
}




/* Additional styles for tooltip */
.tooltip-inner {
  background-color: #333;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.9em;
}

/* Contact Section */
.contact-section {
  padding: 60px 0;
  background-color: #001f3f00; /* Dark blue background to match the theme */
  color: #ffffff; /* White text for contrast */
  text-align: center;
  transition: background-color 0.5s ease; /* Smooth background transition */
}


.section-title {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 40px;
  font-family: Poppins;
  transition: color 0.3s ease; /* Smooth color transition */
}

.contact-form-wrapper {
  background-color: #0074d9; 
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth scale and shadow transition */
}

.contact-form-wrapper:hover {
  transform: scale(1.02); /* Slightly scale up on hover */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.contact-form-wrapper .form-label {
  font-weight: bold;
  color: #ffffff;
}

.contact-form-wrapper .form-control {
  background-color: #004080; /* Darker input background */
  color: #ffffff; /* White text for inputs */
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth background and text color transition */
}

.contact-form-wrapper .form-control::placeholder {
  color: #c0c0c0; /* Light grey placeholder */
}

.contact-form-wrapper .form-control:focus {
  background-color: #003366; /* Even darker background when focused */
  color: #ffffff; /* Maintain white text */
  outline: none; /* Remove default focus outline */
}

.submit-button {
  background-color: #001f3f; /* Dark blue button */
  border-color: #001f3f;
  width: 100%;
  font-size: 18px;
  padding: 10px;
  color: #ffffff;
  margin-top: 20px; 
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth button transitions */
}

.submit-button:hover {
  background-color: #004080; /* Slightly lighter on hover */
  border-color: #004080;
  transform: scale(1.05); /* Slightly scale up on hover */
}

/* Responsive Design */
@media (max-width: 767px) {
  .contact-form-wrapper {
    padding: 20px;
  }
}
